html,
body {
  height: 100%;
  font-size: 1rem;
}

.gradient {
  background: rgb(213, 8, 8);
  background: linear-gradient(
    90deg,
    rgba(213, 8, 8, 1) 0%,
    rgba(151, 255, 0, 1) 100%
  );
}

.page-header {
  margin-top: 3rem;
  font-size: 1.25rem;
  color: white;
  font-family: "Quicksand", sans-serif;
}

.demo {
  padding-top: 5rem;
  font-size: 1.25rem;
  color: black;
  font-family: "Open Sans", sans-serif;
}

.demo-images {
  margin: 1rem;
  font-size: 0.9rem;
}

.download {
  margin-top: 5rem;
  font-size: 1.25rem;
  color: black;
  font-family: "Open Sans", sans-serif;
}

.badges {
  margin-bottom: 5rem;
}

.map-container {
  height: 400px;
}
.details-container {
  margin-top: 1rem;
}
.img-container {
  height: 400px;
}
